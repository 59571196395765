import React, { useState } from "react";
import "../HomePage/HomePage.css";
import { Link, useNavigate } from "react-router-dom";
import { BsEyeFill, BsEyeSlashFill, BsFileEarmarkPdf } from "react-icons/bs";
import pdf from "../../asset/pdf/TNT white paper.pdf";
function PasswordPage() {
  const [isVerified, setIsVerified] = useState(false);
  const [passerr, setPasserr] = useState("");
  const [password, setPassword] = useState("");
  const [passVisibility, setPassVisibility] = useState(false);
  const navigate = useNavigate("");

  const checkPw = () => {
    // gets the current input value
    if (password === "Tntmoney123") {
      setIsVerified(true);
      navigate("/home");
    } else {
      setPasserr("Please Enter Valid Password");
    }
    if (password === "") {
      setPasserr("Please Enter Password");
    }
  };
  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };
  return (
    <>
      <section
        data-bs-version="5.1"
        className="header4 cid-ubrQb7lcy4 password-main"
        id="header04-4"
      >
        <div className="container-fluid">
          <div className=" justify-content-center">
            <div className="container">
              <center>
                <div className="password-box">
                  <div className="mt-2 text-start">Password</div>
                  <div className="d-flex " style={{gap:"10px"}}>
                    <div className="d-flex">
                      <input
                        id="password"
                        type={passVisibility ? "text" : "password"}
                        className="pass-input mt-1"
                        placeholder="Enter Password"
                        onChange={(e) => [
                          setPassword(e.target.value),
                          setPasserr(""),
                        ]}
                      />
                      <span className="pass-show-eye">
                        <button
                          className="reg-eye"
                          onClick={(e) => changeVisibility(e)}
                        >
                          {passVisibility === true ? (
                            <BsEyeFill />
                          ) : (
                            <BsEyeSlashFill />
                          )}
                        </button>
                      </span>
                    </div>
                    <div>
                      <button className="submit-btn w-100" onClick={checkPw}>
                        Submit
                      </button>
                    </div>
                  </div>

                  <span className="d-flex mt-1 errstyle">{passerr}</span>
                  {/* <div>
                    <button className="mt-4 submit-btn" onClick={checkPw}>
                      Submit
                    </button>
                  </div> */}
                  <br />
                  {/* <a href={pdf} download="TNT white paper" className="LinkTextDownload" >
                    <button className="downloadBtn">
                      Download 
                      <BsFileEarmarkPdf />
                    </button>
                    About TNT
                  </a> */}
                  <button
                    className="submit-btn w-50"
                    onClick={() => navigate("/TNT_W_Papers")}
                  >
                    TNT Papers
                  </button>
                </div>
              </center>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PasswordPage;
