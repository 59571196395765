import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Components/HomePage/HomePage";
import PasswordPage from "./Components/HomePage/PasswordPage";
import TNTWpapers from "./Components/HomePage/TNTWPapers";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<TNTWpapers />} />
          {/* <Route path="/home" element={<HomePage />} /> */}
          {/* <Route path="/TNT_W_Papers" element={<TNTWpapers />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
